<template>
 <div class="v_money_detail g-flex-column">
   <head-back :isBack="true">
    <template>
      <span>我的商家</span>
    </template>
  </head-back>
  <div class="v-money-content g-flex-column">
    <p class="v-money-content-tips">
      可对单独的商家进行溢价，溢价的金额全部归代理所有，平台不抽取任何费用
    </p>
    <van-list class="v-money-content-list" v-show="storeList.length!=0" v-model="loading" :finished="finished"  finished-text="没有更多了"  @load="onLoad" :immediate-check="false">
      <div class="v-money-content-list-item" v-for="(item,index) in storeList" :key="index">
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">商家ID: </span>
          <span class="v-money-content-list-item-line-value">{{item.id}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">商家手机号:</span>
          <span class="v-money-content-list-item-line-value">{{item.mobile}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">添加时间:</span>
          <span class="v-money-content-list-item-line-value">{{formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">商家溢价费用:</span>
          <div class="v-money-content-list-item-line-value g-flex-align-center">
             <span>{{item.flow_price}}元</span>
             <div class="v-money-content-list-item-line-value-change g-flex-align-center" @click="changePrice(item)">
              <i class="iconfont iconedit-fill"></i>
              <span>修改</span>
             </div>
          </div>
         
        </div>
        
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">推广所得利润:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-balance">{{item.money}}元</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">店铺数量:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-order-total">{{item.shopNums}}</span>
        </div>
        <div class="v-money-content-list-item-line g-flex-align-center">
          <span class="v-money-content-list-item-line-title">放单总数:</span>
          <span class="v-money-content-list-item-line-value v-money-content-list-item-line-value-order-total">{{item.orderNums}}</span>
        </div>
      </div>
    </van-list>
    <NoList v-show="storeList.length == 0"/> 

    <my-store-pop ref="MyStorePop" @emitSaveSuccess="emitSaveSuccess"/>
  </div>
 </div>
</template>

<script>
import HeadBack from '../../components/HeadBack.vue'
import { PAGE, LIMIT } from '@/utils/constants.js'
// apiGetSpreadList
import { apiGetShopSpreadList } from '@/utils/api.js'
import { formatDate } from '@/utils/index.js'
import MyStorePop from './MyStorePop.vue'
export default {
  components: { HeadBack, MyStorePop },
  data() {
    return {
      show: false,
      minDate: new Date(2004, 0, 1),
      maxDate: new Date(),
      num: 10,
      messageList: [],
      storeList: [],
      form: {
        page: PAGE,
        limit: LIMIT,
      },
      loading: false,
      finished: false,

    }
  },
  created() {
    this.apiGetShopSpreadListHandel()
  },
  methods: {
    formatDate,
    onLoad() {
      this.form.page++
      this.apiGetSpreadListHandel()
    },

    changePrice(item) {
      this.$refs.MyStorePop.onShow(item)
    },

    // 获取商家列表
    async apiGetShopSpreadListHandel() {
      this.$global.loadingShow = true
      const { success, data } = await apiGetShopSpreadList(this.form)
      if(!success) return
      this.storeList = this.storeList.concat(data.list)
      this.finished = data.list.length < this.form.limit
    },

    emitSaveSuccess() {
      this.form.page = 1
      this.storeList = []
      this.loading = true
      this.finished = false
      this.apiGetShopSpreadListHandel()
    }
  }
}
</script>

<style lang='scss'>
.v_money_detail {
  height: 100%;
  overflow: auto;
  .iconrili {
    font-size: 20px;
    color: $main_color;
    height: 100%;
    padding: 0 0 0 8px;
  }
  .v-money-content {
    flex: 1;
    overflow: auto;
    background: #F1F1F1;
    border-radius: 8px;
    .v-money-content-tips {
      color: #959AA3;
      padding: 5px 20px;
      font-size: 14px;
      line-height: 20px;
    }
    .v-money-content-list {
      padding: 8px 16px;
      background: #F1F1F1;
      flex: 1;
      overflow: auto;
      .v-money-content-list-item {
        background: $white;
        margin-top: 14px;
        border-radius: 8px;
        padding: 20px 18px;
        &:nth-of-type(1) {
          margin-top: 0;
        }
        .v-money-content-list-item-line {
          padding-top: 18px;
          font-size: 13px;
          color: #0B0B0B;
          &:nth-of-type(1) {
            padding-top: 0;
          }
          .v-money-content-list-item-line-title {
            min-width: 40px;
          }
          .v-money-content-list-item-line-value {
            flex: 1;
            padding-left: 10px;
            word-break: break-all;
            .v-money-content-list-item-line-value-change {
              padding: 5px 10px;
              color: $main_color;
              line-height: 20px;
              .iconfont {
                font-size: 20px;
                color: $main_color;
              }
            }
            
            &.v-money-content-list-item-line-value-money {
              color: #2473EF;
              font-size: 18px;
              font-weight: bold;
            }
            &.v-money-content-list-item-line-value-balance {
              color: #FF6A00;
              font-size: 18px;
              font-weight: bold;
            }
            &.v-money-content-list-item-line-value-order-total {
              color: #2473EF;
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>