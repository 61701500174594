<template>
  <div class="s_my_store_pop">
    <van-popup v-model="show" class="s-my-store-pop-container" style="width: 80%">
      <div class="c-task-cancel-close" @click="onCancel">
       <i class="iconfont iconguanbi"></i> 
      </div>
      <div class="s-my-store-top">
        <p class="s-my-store-title">{{title}}</p>
      </div>
      <div class="s-my-store-middle">
        <van-cell-group>
          <van-field label-width="10em" v-model="form.flow_price" type="number" label="商家溢价费用(元)：" />
        </van-cell-group>
      </div>

      <div class="s-my-store-bottom">
        <ul class="s-my-store-btn-list g-flex-align-center">
          <li class="s-my-store-btn-item g-flex-justify-center g-flex-align-center" v-for="(item, index) in btnList" :key="index" @click="itemClick(item)">
            <span>{{item.name}}</span>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { apiUpFlowPrice } from '@/utils/api.js'
  export default {
    props: {
      title: {
        type: String,
        default() {
          return '修改商家溢价费用'
        }
      }
    },
    data() {
      return {
        btnList : [
          { name: '取消', event: 'onCancel' },
          { name: '确认修改', event: 'apiUpFlowPriceHandel' }
        ],
        form: {
          id: '',
          flow_price: ''
        },
        number: 1,
        show: false
      }
    },
    methods: {
      onShow(obj) {
        this.form.id = obj.id
        this.form.flow_price = obj.flow_price
        this.show = true
      },
      onCancel() {
        this.show = false
      },
      itemClick(item) {
        this[item.event]()
      },
      async apiUpFlowPriceHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiUpFlowPrice(this.form)
        if(!success) return
        this.$toast(data.msg)
        this.$emit('emitSaveSuccess')
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.s_my_store_pop {
  .s-my-store-pop-container {
    border-radius: 8px;

    .c-task-cancel-close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      .iconfont {
        font-size: 20px;
        color: #828282;
      }
    }

    .s-my-store-top {
      font-size: 16px;
      color: #000000;
      text-align: center;
      padding-top: 15px;
    }

    .s-my-store-middle {
      font-size: 16px;
      padding: 20px 26px 10px 26px;
    }

    .s-my-store-bottom {
      padding: 15px 16px 15px 16px;
      .s-my-store-btn-list {
        background: $main_color;
        border-radius: 20px;
        .s-my-store-btn-item {
          flex: 1;
          color: $white;
          font-size: 16px;
          padding: 10px 0;
          span {
            flex: 1;
            text-align: center;
          }
          &:nth-of-type(1) {
            span {
              border-right: 1px solid $white;
            }
          }
          &:nth-last-of-type(1) {
            span {
              border-left: 1px solid $white;
            }
          }
        }
      }
    }

  }
}
</style>